@import '../../shared/settings';

.modal-frontoffice-success {
    padding: 140px 0;
    text-align: center;

    h3 {
        margin: 10px 0;
        line-height: 30px;
        font-size: 20px;
    }

    .modal-frontoffice-success-checkmark {
        display: block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 100%;
        background: var(--success);
        width: 62px;
        height: 62px;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 26px;
            height: 23px;
            fill: white;
        }
    }

    .btn-link {
        text-decoration: underline;
        line-height: 24px;
        color: $grey;
        font-size: 14px;
    }
}

.modal-frontoffice-form {
    .errors {
        margin: 20px 0 0;
        border-radius: 5px;
        background-color: var(--danger);
        padding: 12px 20px;
        color: white;

        p {
            line-height: 26px;
            font-size: 16px;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    h3 {
        margin: 0 0 12px;
        line-height: 30px;
        font-size: 20px;
        font-weight: bold;
    }

    p {
        margin: 24px 0;
        line-height: 26px;
        font-size: 16px;
    }

    h3 + p {
        margin-top: 0;
    }

    .btn-success {
        margin-top: 24px;
    }

    @include respond-to(mobile) {
        h3 {
            margin: 0 24px 4px 0;
            line-height: 26px;
            font-size: 16px;
        }

        p {
            margin: 0 0 24px;
            line-height: 24px;
            font-size: 14px;
        }
    }
}
