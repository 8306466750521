@import '../../shared/settings';
@import '../../backoffice/settings';
@import '../../mixins/scrollbar';

.zupr-dropdown {
    z-index: 101;
    border: 1px solid $light-grey;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(black, 0.09);
    background: white;
    width: 320px;
    text-align: left;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .zupr-dropdown-overflow {
        @include styledScrollbar;
        max-height: calc(100vh - 280px);
        overflow: auto;
    }

    .zupr-dropdown-header {
        padding: 14px;
        text-align: left;
        font-size: 14px;
        font-weight: var(--medium);
    }

    &::before {
        @include triangle(14px, $light-grey, up);
        position: absolute;
        top: -7px;
        left: 50%;
        transform: translateX(-50%);
        content: ' ';
    }

    &::after {
        @include triangle(12px, white, up);
        position: absolute;
        top: -6px;
        left: 50%;
        transform: translateX(-50%);
        content: ' ';
    }

    header,
    .header {
        background-color: transparent;
        padding: 12px 20px 13px;
        min-height: 0;
        text-align: center;
        font-size: 16px;
        font-weight: var(--medium);

        p {
            margin: 7px 0 0;
            line-height: 20px;
            color: $grey;
            font-size: 14px;
            font-weight: normal;
        }
    }

    .confirm {
        padding: 13px 20px 20px;
        text-align: center;
        line-height: 24px;
        color: $grey;

        p {
            margin: 0 0 12px 0;
            line-height: 24px;
            font-size: 14px;

            &:first-child {
                margin-top: -2px;
            }
        }

        hr {
            margin: 0 -20px;
            width: calc(100% + 40px);
        }

        button {
            margin: 0 5px;
        }
    }

    &.filter {
        display: block;
        top: auto;
        right: auto;
        margin-left: -122px;
        border-radius: 5px;
        width: 274px;

        .top {
            border-bottom: 1px solid $light-grey;
            padding: 11px 15px 12px 14px;
            box-sizing: border-box;

            input {
                &[type='text'] {
                    border-radius: 4px;
                    border-color: $light-grey;
                    background: $lighter;
                    padding: 0 30px 0 11px;
                    height: 32px;
                    text-indent: 0;
                    color: $grey;
                    font-size: 14px;
                }
            }
        }
    }

    ul {
        background: none;

        span {
            &:hover {
                box-shadow: none;
            }

            li,
            li:last-child {
                border-bottom: 1px solid $light-grey;
            }
        }

        .save {
            border-top: 1px solid $light-grey;
            height: 76px;
            text-align: center;
            line-height: 76px;
        }

        li {
            position: relative;
            border-top: 0;
            border-bottom: 1px solid $light-grey;
            cursor: pointer;
            padding: 15px 14px 16px;
            line-height: 22px;
            color: $grey;
            font-size: 18px;
            font-weight: var(--medium);

            &:last-child {
                border-bottom: 0;
            }

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:hover {
                background-color: rgba($light, 0.3);
                color: $dark-grey;

                &.next {
                    &::after {
                        @include triangle(8px 5px, $dark-grey, right);
                    }
                }
            }

            &.title {
                text-align: center;
                color: $dark-grey;
                font-size: 18px;
                font-weight: var(--medium);

                .back {
                    display: inline-block;
                    position: absolute;
                    left: 14px;
                    margin-top: 1px;
                    margin-left: 11px;
                    text-align: left;
                    color: $brand;
                    font-size: 16px;
                    font-weight: 400;

                    &::after {
                        @include triangle(8px 5px, $brand, left);
                        position: absolute;
                        top: 50%;
                        left: -11px;
                        margin-top: -5px;
                        content: '';
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }

                &:hover {
                    box-shadow: none;
                }
            }

            .btn-switch {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            }

            &.next {
                &::after {
                    @include triangle(8px 5px, $light-grey, right);
                    position: absolute;
                    top: 50%;
                    right: 14px;
                    margin-top: -4px;
                    content: ' ';
                }
            }

            &.forward {
                padding-right: 82px + 14px + 14px;
                font-size: 16px;
                font-weight: 400;

                &.active {
                    color: var(--success);

                    .badge {
                        background: var(--success);
                        color: white;
                    }
                }

                .badge {
                    position: absolute;
                    top: 13px;
                    right: 14px;
                    border-radius: 3px;
                    background: $grey;
                    padding: 3px 8px 4px;
                    text-transform: lowercase;
                    line-height: 16px;
                    color: white;
                    font-size: 14px;
                    font-weight: var(--medium);

                    &.active {
                        background: var(--success);
                    }
                }

                &:hover {
                    color: $dark-grey;

                    &.active {
                        .badge {
                            background: var(--success);
                        }
                    }

                    .badge {
                        background: $secondary;
                    }
                }
            }
        }

        &.with-figure {
            li {
                padding: 10px;

                h3,
                p {
                    margin: 0;
                }

                &:first-child {
                    border-top: 0;
                }

                &::after {
                    display: none;
                }

                &:hover {
                    background-color: transparent;

                    .block {
                        background: $lighter;

                        h3 {
                            color: $brand;
                        }

                        p {
                            color: $grey;
                        }
                    }
                }

                .block {
                    display: block;
                    position: relative;
                    padding: 6px 6px 6px 56px;
                    height: 52px;
                    line-height: 13px;

                    svg {
                        &.zupr-pin {
                            position: absolute;
                            top: 14px;
                            left: 18px;
                            z-index: 4;
                            height: 25px;
                        }
                    }

                    figure {
                        position: absolute;
                        top: 6px;
                        left: 6px;
                        border-radius: 3px;
                        background-size: cover;
                        width: 40px;
                        height: 40px;
                    }

                    h3 {
                        @extend %nowrap;
                        line-height: 22px;
                        color: $dark-grey;

                        font-size: 14px;
                        font-weight: var(--medium);
                    }

                    p {
                        @extend %nowrap;
                        line-height: 16px;
                        font-size: 12px;
                        font-weight: normal;
                    }
                }
            }
        }
    }
}

.dropdown-menu {
    width: auto;
    ul {
        li {
            padding: 8px 84px 8px 14px;
            color: $dark-grey;
            font-size: 14px;

            a,
            button {
                color: inherit;
                &.inline {
                    margin: -8px -84px -8px -14px;
                    padding: 8px 84px 8px 14px;
                }
            }
        }
    }
}

.dropdown-multiselect {
    width: auto;
    min-width: 240px;

    .header {
        border-bottom: 1px solid $light-grey;
        padding: 14px;
    }

    .multiselect {
        border: 0;
        padding: 8px 0;

        & + .multiselect {
            border-top: 1px solid $light-grey;
        }

        ul {
            @include styledScrollbar(5px, $grey, $light);
            max-height: 40vh;
            overflow: auto;

            li {
                border: 0;
                min-height: initial;
                font-weight: normal;
                display: flex;
                padding: 4px 10px;
                align-items: center;

                &:hover {
                    background-color: $lighter;
                }

                .checkbox,
                .loading {
                    margin-right: 8px;
                }

                .checkbox {
                    position: relative;
                    top: initial;
                    left: initial;

                    &.checkbox-checked + label {
                        background-color: transparent;
                    }
                }

                label {
                    font-size: 16px;
                    padding: 0;
                    line-height: 22px;

                    strong {
                        font-weight: var(--medium);
                    }

                    small {
                        margin-left: 5px;
                        font-size: inherit;
                    }
                }
            }
        }
    }
}
